import * as React from "react";

import Home from './components/Home';
import { Routes, Route } from "react-router-dom";

import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import CookiePolicy from "./components/policies/CookiePolicy";
import TermsOfService from "./components/policies/TermsOfService";
import NoMatch from "./components/NoMatch";

function App() {
  return (
    <Routes>
      <Route>
        <Route path="/" index element={<Home />}/>
        <Route path="/policies/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/policies/cookie" element={<CookiePolicy/>}/>
        <Route path="/policies/terms-of-service" element={<TermsOfService/>}/>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
