import '../App.css';
import InstagramLogo from './InstagramLogo';
import ProductTile from './ProductTile';

function Home() {
  return (
    <div className="App">
      <section className="align-centre-wrapper all-wrappers splashscreen" id="page-top">
        <div className="bg-image"></div>
        <div className="crowzfx-box">
          <h1>CR<span className="colour-modifier">&#123;&#125;</span>WZFX</h1>
          <p>here to help you achieve your ideas today</p>
        </div>
        <span id="bouncing-indicator">&rsaquo;&rsaquo;</span>
        <svg className="clip-splash-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fillOpacity="1" d="M0,32L80,64C160,96,320,160,480,165.3C640,171,800,117,960,96C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        </svg>
      </section>
      <section className="all-wrappers align-top-wrapper about" id="page-about">
        <div className="section-content">
          <h1>About Us</h1>
          <p>Primarily what we do is bring your ideas to life. All of those plans and light-bulb moments we can bring to fruition.</p>
          <p>With a key focus on development ranging from fully-fledged software to custom solutions, from intricate problems to apps and websites; no project too small!</p>
          <p>Although our primary focus is on development we also have the capability to do design, 3D print and build custom electonic solutions too.</p>
        </div>
      </section>
      <svg className="svg-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fillOpacity="1" d="M0,96L80,106.7C160,117,320,139,480,128C640,117,800,75,960,53.3C1120,32,1280,32,1360,32L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      <section className="all-wrappers align-top-wrapper products" id="page-products">
        <div className="section-content">
          <h1>Products</h1>
          <p>Links to services and products</p>
          <div className="product-tile-wrapper">
            <ProductTile name="tipsnslips" status="In Development" description="Sports analysis and insights platform" link="https://tipsnslips.co.uk" />
            <ProductTile name="shortn4.me" status="In Development" description="URL shortener and insights platform" link="https://shortn4.me" />
            <ProductTile name="taskie" status="Live" description="Simple, offline, no frills task management Android app" link="https://play.google.com/store/apps/details?id=com.crowzfx.app.taskie" />
            <ProductTile name="tilter" status="Planned" description="Want to measure an angle but don't have a protractor? Use this Android app" link="" />
            <ProductTile name="whoscodeisthis" status="Offline" description="Code snippets, memes and general reference for all things coding" link="https://whoscodeisthis.co.uk" />
          </div>
        </div>
      </section>
      <svg className="svg-alt-rev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fillOpacity="1" d="M0,192L80,176C160,160,320,128,480,144C640,160,800,224,960,240C1120,256,1280,224,1360,208L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      <section className="all-wrappers align-top-wrapper contact" id="page-contact">
        <div className="section-content">
          <h2>Got a query? Get in touch!</h2>
        </div>
      </section>
      <svg className="svg-alt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fillOpacity="1" d="M0,192L80,176C160,160,320,128,480,144C640,160,800,224,960,240C1120,256,1280,224,1360,208L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      <section className="footer all-wrappers" id="page-footer">
        <div className="footer-top">
          <div className="footer-top-left">
            <h3>Contact</h3>
            <a className="footer-link" href="mailto:contact@crowzfx.co.uk">contact@crowzfx.co.uk</a>
            <div>
              <InstagramLogo value="crowzfx" />
              {/* <InstagramLogo value="crowzfx_tinkering" /> */}
            </div>
          </div>
          <div className="footer-top-middle">
            <h3>Navigation</h3>
            <ul className="footer-ul">
              <li><a className="footer-link" href="#page-about">About Us</a></li>
              <li><a className="footer-link" href="#page-products">Products</a></li>
              <li><a className="footer-link" href="#page-contact">Contact</a></li>
              <li><a className="footer-link" href="/policies/privacy">Privacy Policy</a></li>
              <li><a className="footer-link" href="/policies/cookie">Cookie Policy</a></li>
              <li><a className="footer-link" href="/policies/terms-of-service">Terms of Service</a></li>
            </ul>
          </div>
          <div className="footer-top-right">
            <h3>Address</h3>
            <ul className="footer-ul">
              <li>71-75 Shelton Street</li>
              <li>Covent Garden</li>
              <li>London</li>
              <li>UK</li>
              <li>WC2H 9JQ</li>
            </ul>
          </div>
        </div>
        <hr></hr>
        <div className="footer-bottom">
          <div className="footer-bottom-left">
            <p>&#169; 2022-24 CROWZFX LTD. All rights reserved. A company registered in England and Wales with company number 14188096.</p>
            <p>Registered address: 71-75 Shelton Street, Covent Garden, London, UK, WC2H 9JQ.</p>
          </div>
          <div className="footer-bottom-right">
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
