
function TermsOfService() {
  return (
    <div class="policy-wrapper">
      <h1 class="policy-header">Terms and Conditions of Use</h1>
      <a class="policy-link" href="/">&lt;&lt; Return to home page</a>

      <h2>A. Definitions</h2>
      <p>The following definitions will be referred to throughout this document and mean the following:</p>
      <ul>
        <li>'<span class="highlighted-word">Offerings</span>' - Refers to any products or services that has been developed, coded, offered, deployed by CROWZFX LTD. This includes, but not limited to: websites, sevrices, platforms, mobile applications.</li>
        <li>'<span class="highlighted-word">Company</span>'/'<span class="highlighted-word">Business</span>'/'<span class="highlighted-word">CROWZFX</span>' - Refers to the business of CROWZFX LTD and any trading names, services and/or products that is owned and offered.</li>
      </ul>

      <h2>1. Terms</h2>
      <p>By accessing, utilising, signing-up, purchasing, downloading or installing any <span class="highlighted-word">offerings</span> offered by <span class="highlighted-word">CROWZFX</span>, you are agreeing to be bound by these Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited and should immediately discontinue use. The materials contained in these <span class="highlighted-word">offerings</span> are protected by copyright and trademark law.</p>

      <h2>2. Use License</h2>
      <p>Permission is granted to utilise <span class="highlighted-word">offerings</span> from the <span class="highlighted-word">Company</span> for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
      <ul>
        <li>modify or copy the materials;</li>
        <li>use the materials for any commercial purpose or for any public display;</li>
        <li>attempt to reverse engineer any software contained in any of the <span class="highlighted-word">offerings</span>;</li>
        <li>remove any copyright or other proprietary notations from the materials; or</li>
        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
      </ul>
      <p>Doing any of these will immediately allow the <span class="highlighted-word">Company</span> to terminate your access upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

      <h2>3. Disclaimer</h2>
      <p>All the materials on/in the <span class="highlighted-word">Companies</span> <span class="highlighted-word">offerings</span> are provided "as is". The <span class="highlighted-word">CROWZFX</span> makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, <span class="highlighted-word">CROWZFX</span> does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

      <h2>4. Limitations</h2>
      <p><span class="highlighted-word">CROWZFX</span> or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on <span class="highlighted-word">CROWZFX's</span> <span class="highlighted-word">offerings</span>, even if <span class="highlighted-word">CROWZFX</span> or an authorised representative has been notified, orally or written, of the possibility of such damage. Some jurisdictions do not not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

      <h2>5. Revisions and Errata</h2>
      <p>The materials appearing on <span class="highlighted-word">CROWZFX's</span> <span class="highlighted-word">offerings</span> may include technical, typographical, or photographic errors. <span class="highlighted-word">CROWZFX</span> will not promise that any of the materials in this Website are accurate, complete, or current. <span class="highlighted-word">CROWZFX</span> may change the materials contained with its <span class="highlighted-word">offerings</span> at any time without notice. <span class="highlighted-word">CROWZFX</span> does not make any commitment to update the materials.</p>

      <h2>6. Links</h2>
      <p><span class="highlighted-word">CROWZFX</span> has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by <span class="highlighted-word">CROWZFX</span> of the site. The use of any linked <span class="highlighted-word">offerings</span> is at the user's own risk.</p>

      <h2>7. Site Terms of Use Modifications</h2>
      <p><span class="highlighted-word">CROWZFX</span> may revise these Terms of Use for its Website at any time without prior notice. By using any of these <span class="highlighted-word">offerings</span>, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

      <h2>8. Your Privacy</h2>
      <p>Please read our <a href="/policies/privacy">Privacy Policy</a> for more information.</p>

      <h2>9. Governing Law</h2>
      <p>Any claim related to <span class="highlighted-word">CROWZFX's</span> <span class="highlighted-word">offerings</span> shall be governed by the English laws of the United Kingdom without regards to its conflict of law provisions.</p>

      <div>
        <hr />
        <p>&#169; 2022-24 CROWZFX LTD. All rights reserved. A company registered in England and Wales with company number 14188096.</p>
        <p>Registered address: 71-75 Shelton Street, Covent Garden, London, UK, WC2H 9JQ.</p>
      </div>
    </div>
  );
}

export default TermsOfService;
