import * as React from "react";
import { useNavigate } from "react-router-dom";

function NoMatch() {
  const navigate = useNavigate();
  const [time, setTime] = React.useState(10);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time < 1) navigate("/");
      setTime((counter) => {
        return counter - 1
      })
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div class="nomatch-wrapper">
      <h1 class="nomatch-header">404 - Not Found</h1>
      <a class="nomatch-link" href="/">&lt;&lt; Return to home page</a>
      <p>We haven't been able to locate the page you're looking for; redirecting you to the home page in in {time} seconds...</p>
    </div>
  )
}

export default NoMatch;