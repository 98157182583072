import '../App.css';

function ProductTile(props) {

  let statusClassNameMapping = {
    "In Development": "orange",
    "Live": "green",
    "Planned": "red",
    "Offline": "red"
  }

  return (
    <a className="product-tile-a" href={`${props.link}`}>
        <h3>{props.name}</h3>
        <p className={`product-p-status product-status-${statusClassNameMapping[props.status]}`}><i>({props.status})</i></p>
        <p>{props.description}</p>
    </a>
  );
}

export default ProductTile;